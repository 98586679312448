import React from 'react';

import Container from '../Container';
import { CtaButtonPlaceholder } from '../Cta/placeholders';

import { TitleColour } from '../../utils/variables';

import loadComponents from '../Loadable';

const Button = loadComponents('cta-button');

export default function FooterCta() {
  return (
    <div
      className="mobileCta"
      style={{
        position: `fixed`,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: TitleColour,
        padding: `15px 0`,
        zIndex: 4000,
      }}
    >
      <Container>
        <Button size="100%" fallback={<CtaButtonPlaceholder size="100%" />} />
      </Container>
    </div>
  );
}
